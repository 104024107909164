import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "CapStructLogo.png" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      logoHorizImage: file(
        relativePath: { eq: "CapStructHorizontalLogo.png" }
      ) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)

  return (
    <header>
      <div className="header-stripe py-2 text-right">
        <Container>
          <span className="contact-number mx-3">479.783.8666</span>
          <Button href="/contact" variety="primary" size="sm">
            Contact Us
          </Button>
        </Container>
      </div>
      <Navbar expand="md">
        <Container className="flex-md-wrap">
          <Navbar.Brand href="/">
            <img
              src={data.logoImage.childImageSharp.fluid.src}
              alt="Logo"
              className="nav-logo d-none d-md-inline-block align-top"
            />
            <img
              src={data.logoHorizImage.childImageSharp.fluid.src}
              alt="Logo"
              className="nav-logo d-md-none d-inline-block align-top"
            />
            {/* {siteTitle} */}
          </Navbar.Brand>
          <Button
            href="/contact"
            variant="outline-primary"
            size="sm"
            className="d-md-none ml-auto"
          >
            Contact Us
          </Button>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-md-auto">
              <Nav.Link href="/about-us">About Us</Nav.Link>
              <Nav.Link href="/roof-floor-trusses">
                Roof &amp; Floor Trusses
              </Nav.Link>
              <Nav.Link href="/beams-i-joists">Beams &amp; I-Joists</Nav.Link>
              <Nav.Link href="/engineering-services">
                Engineering Services
              </Nav.Link>
              <Nav.Link href="/projects">Projects</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
