/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Container from "react-bootstrap/Container"

import Header from "./header"
import Footer from "./footer"
import OurProjects from "./ourProjects"
import Partners from "./partners"
import "../scss/styles.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6LcuoboUAAAAAHRZEkWw8KnbHpqte1lvc5Dgep8I"></script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>
        <Container>{children}</Container>
      </main>
      <Partners />
      <Container>
        <OurProjects />
      </Container>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
