import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "CapStructLogo.png" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)

  return (
    <footer className="pt-4">
      <Container className="clearfix">
        <img
          src={data.logoImage.childImageSharp.fluid.src}
          alt="Logo"
          className="float-right"
        />
        <Button href="/contact" variant="primary" size="sm" className="mb-3">
          Contact Us
        </Button>
        <address>
          <b>Main Office</b>
          <br />
          900 Atlanta Street
          <br />
          Fort Smith, AR 72901
          <br />
          <a href="https://www.google.com/maps/place/Capital+Structures/@35.3561431,-94.4262731,17z/data=!3m1!4b1!4m5!3m4!1s0x87ca4ce3d27d2641:0xab60695736d59544!8m2!3d35.3561431!4d-94.4240844">
            Get Directions
          </a>
        </address>
        <address>
          <b>Sales &amp; Design Office</b>
          <br />
          900 Atlanta Street
          <br />
          Fort Smith, AR 72901
          <br />
          <a href="https://www.google.com/maps/place/Capital+Structures/@35.3561431,-94.4262731,17z/data=!3m1!4b1!4m5!3m4!1s0x87ca4ce3d27d2641:0xab60695736d59544!8m2!3d35.3561431!4d-94.4240844">
            Get Directions
          </a>
        </address>
      </Container>
      <p className="m-0 mt-5 py-3 text-center">
        © Copyright {new Date().getFullYear()} Capital Structures
      </p>
    </footer>
  )
}

export default Footer
