import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const OurProjects = () => {
  const data = useStaticQuery(graphql`
    query {
      galleryImages: allFile(
        filter: { relativeDirectory: { eq: "gallery" } }
        limit: 3
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section className="our-projects py-5">
      <h2 className="mb-3">Our Projects</h2>
      <div className="d-flex flex-row flex-wrap justify-content-center justify-content-sm-around">
        {data.galleryImages.edges.map(image => (
          <a href="/projects" key={image.node.childImageSharp.fluid.src}>
            <img src={image.node.childImageSharp.fluid.src} alt="a project" />
          </a>
        ))}
      </div>
    </section>
  )
}

export default OurProjects
