import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Partners = () => {
  const data = useStaticQuery(graphql`
    query {
      abc: file(relativePath: { eq: "ABC.png" }) {
        publicURL
      }
      awma: file(relativePath: { eq: "awma.png" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      mitek: file(relativePath: { eq: "mitek.jpg" }) {
        publicURL
      }
      nahb: file(relativePath: { eq: "NAHB.png" }) {
        publicURL
      }
      nwa: file(relativePath: { eq: "nwa.jpg" }) {
        publicURL
      }
      quicktie: file(relativePath: { eq: "quicktie.png" }) {
        publicURL
      }
      sbca: file(relativePath: { eq: "SBCA.png" }) {
        publicURL
      }
      sbcmag: file(relativePath: { eq: "SBC_Magazine.png" }) {
        publicURL
      }
    }
  `)

  return (
    <>
      <section className="partners pt-3 px-3 d-flex flex-wrap justify-content-around">
        <a
          href="http://www.arkwood.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={data.awma.childImageSharp.fluid.src} alt="AWMA" />
        </a>
        <a href="http://www.abc.org" target="_blank" rel="noopener noreferrer">
          <img src={data.abc.publicURL} alt="ABC" />
        </a>
        <a href="http://www.nahb.org" target="_blank" rel="noopener noreferrer">
          <img src={data.nahb.publicURL} alt="NAHB" />
        </a>
        <a
          href="http://www.sbcindustry.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={data.sbca.publicURL} alt="SBCA" />
        </a>
        <a
          href="http://www.nwahba.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={data.nwa.publicURL} alt="NWA" />
        </a>
        <a
          href="http://www.quicktieproducts.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={data.quicktie.publicURL} alt="QuickTie" />
        </a>
        <a
          href="http://www.mitek-us.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={data.mitek.publicURL} alt="MiTek" />
        </a>
        <a
          href="http://www.sbcmag.info"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={data.sbcmag.publicURL} alt="SBC Magazine" />
        </a>
      </section>
    </>
  )
}

export default Partners
